<template>
    <mercur-card class="full-height-layout fill mx-4 mb-3" data-test="supplierImportProductStepValueMapping">
        <h2 class="font-weight-normal">
            Value mapping for <span v-if="supplierProductImportedDetails">{{supplierProductImportedDetails.productName}}</span>
        </h2>
        <div v-if="columnMapping" class="fill">
            <div class="row fill height-100">
                <div class="col col-3 scroll-list connection-list">
                    <div class="connection-list pt-0">
                        <field-group
                            v-for="(columnSet, columnSetIndex) in columnMapping"
                            :key="columnSetIndex"
                            :title="columnTitle(columnSet.type, columnSet.columnNames)"
                            class="column field-group--small-title"
                        >
                            <mercur-simple-slide-up-down v-if="sampleData" :duration="100">
                                <template slot="trigger" slot-scope="{ isActive }">
                                    <small>{{ isActive ? 'Hide' : 'Show'}} sample data</small>
                                </template>
                                <code v-for="(sample, key) in getSampleDataForColumn(column)" :key="key">{{sample}}<br/></code>
                            </mercur-simple-slide-up-down>
                            <div
                                class="connection"
                                v-for="(connection, connectionKey) in columnSet.mappedTo"
                                :key="connectionKey"
                                @click="setSelectedConnection(connection, columnSet)"
                                :class="{'connection--is-selected' : isConnectionSelected(connection, columnSet)}"
                            >
                                {{connectionKey}}
                                <span>
                                    <template v-if="getConnectionProgress(connection, columnSet) === 'complete'">
                                        <i class="fas fa-check-circle text-success" />
                                    </template>
                                </span>
                            </div>
                        </field-group>
                    </div>
                </div>
                <div class="col col-3 scroll-list scroll-list--force">
                    <template v-if="!selectedConnection">
                        <p class="message pl-10">Please select a connection first</p>
                    </template>
                    <template v-else>
                        <field-group class="height-100" :title="selectedConnection.connectionName">
                            <template slot="title-right">
                                <mercur-tooltip>
                                    <i class="fas fa-info"></i>
                                    <template slot="label">Mapping details for extracting <em>{{selectedConnection.connectionName}}</em> from the CSV columns <em>{{selectedColumnSet.columnNames.join(', ')}}</em></template>
                                </mercur-tooltip>
                            </template>

                            <mercur-select v-model="selectedConnection.type">
                                <template slot="label">Rule</template>
                                <option value="VALUE">Exact</option>
                                <option value="DELIMITER">Delimiter</option>
                                <option value="REGEX">Regex</option>
                            </mercur-select>
                            <template v-if="selectedConnection.type !== 'VALUE'">
                                <mercur-input v-model="selectedConnection.value">Value</mercur-input>
                                <template v-if="selectedConnection.type === 'REGEX'">
                                    <span class="explanation">Regular expression to extract data: Sample for legacy sku /^(.*)-(.*)-(.*)-(.*)-(.*)-(dummy)(\[[a-zA-Z0-9+-:\/_]+\])?/</span>
                                    <mercur-input v-model="selectedConnection.index" type="number" step="1">
                                        <label>Position</label>
                                    </mercur-input>
                                    <span class="explanation">(Positions start at zero)</span>
                                </template>
                                <template v-else>
                                    <span class="explanation">(Delimiter is a single character to split the content. Eg: comma (,), semi-column(;), space ( ) etc.)</span>
                                    <span v-if="firstItemOfSampleData"><strong>Sample: {{ firstItemOfSampleData }}</strong></span>
                                    <alert type="danger" v-else>Sample data is missing!</alert>
                                    <p>Select radio button to define position:</p>
                                    <ul class="radio-list" v-if="selectedConnection.value">
                                        <li v-for="(value, index) in explodedValue" :key="value + index"><mercur-radio v-model.number="selectedConnection.index" :value="index">{{ value }}</mercur-radio></li>
                                    </ul>
                                </template>
                            </template>
                            <mercur-button
                                @click="storeSelectedConnection"
                                class="btn btn-yellow"
                                :disabled="isSavingProduct || !columnMapping"
                            >Save rule</mercur-button>
                        </field-group>
                    </template>
                </div>
                <div class="col col-6 scroll-list scroll-list--force" v-if="selectedConnection">
                    <template v-if="selectedColumnSet.type === 'ATTRIBUTE'">
                        <template v-if="distinctValues">
                            <field-group :title="`${Object.keys(distinctValues).length} values to map`">
                                <p>The rule you have set up resulted in {{Object.keys(distinctValues).length}} values to map to attribute {{selectedConnection.connectionName}}.</p>
                                <p v-if="!Object.keys(distinctValues).length && !isGettingImportedValues" class="error">Unable to fetch data with current rule. Please change and try again.</p>
                                <template v-else>
                                    <p v-if="selectedColumnSet.columnNames.length > 1">The values have been concatenated because multiple columns have been selected in the mapping. Hover over the value to show the original values.</p>
                                    <div class="row no-gutters">
                                        <span class="col col-5">From:</span>
                                        <span class="col col-7">To:</span>
                                    </div>
                                    <div class="mt-10" v-if="isGettingImportedValues">
                                        <mercur-spinner></mercur-spinner>
                                    </div>
                                    <template v-if="!isGettingImportedValues">
                                        <div
                                            v-for="(mapped, distinctValue) in distinctValues"
                                            :key="`${selectedConnection.connectionName}_${distinctValue}`"
                                            class="row no-gutters"
                                        >
                                            <span class="col col-5">
                                                <strong
                                                    :class="{'error': !getConnectionMappingValue(selectedColumnSet.columnNames, selectedConnection.connectionName, distinctValue) || !getConnectionMappingValue(selectedColumnSet.columnNames, selectedConnection.connectionName, distinctValue).attributeId}"
                                                >
                                                    <mercur-tooltip>
                                                        <template
                                                            slot="label"
                                                            v-if="mapped && mapped.details && Object.keys(mapped.details.originalValues).length > 1"
                                                        >
                                                            <span v-for="(originalValue, originalColumnName) in mapped.details.originalValues" :key="originalColumnName">
                                                        {{originalColumnName}}: {{originalValue}}<br />
                                                    </span>
                                                        </template>
                                                        {{ distinctValue }}
                                                    </mercur-tooltip>
                                                </strong>

                                            </span>

                                            <div class="col col-7">
                                                <pretty-select
                                                    v-if="sortedOptions && !isAddingCustomOption"
                                                    @input="handleConnectionValueSelection(selectedColumnSet.columnNames, selectedConnection.connectionName, distinctValue, $event)"
                                                    :value="getConnectionMappingValue(selectedColumnSet.columnNames, selectedConnection.connectionName, distinctValue).attributeId"
                                                    :options="sortedOptions"
                                                    :reduce="option => option.attributeId"
                                                    :getOptionKey="option => option.attributeId"
                                                    placeholder=""
                                                    :get-option-label="option => option.option + (option.postfix || '')"
                                                    class="inline-select"
                                                >
                                                    <template slot="option" slot-scope="slotData" >
                                                        <span :class="{'blue u-bold': isTopArray(slotData)}">{{ slotData.option }} <span v-if="slotData.postfix && slotData.postfix !== ''">({{ slotData.postfix }})</span></span>
                                                    </template>
                                                </pretty-select>
                                                <div class="inline-add">
                                                    <add-attribute-option-component
                                                        :passed-method="addCustomOption"
                                                        :parent-key="distinctValue"
                                                        :attribute-name="selectedConnection.connectionName"
                                                        :attribute-validations="attributeValidations"
                                                    ></add-attribute-option-component>
                                                </div>
                                                <span class="recommended-message" v-if="mapped && mapped.isRecommended">Platform recommended preset value</span>
                                            </div>
                                        </div>
                                    </template>

                                    <div class="align-right">
                                        <mercur-button
                                            v-if="Object.keys(distinctValues).length !== 0"
                                            class="btn btn-yellow save-connection"
                                            :disabled="isSavingProduct"
                                            @click="save"
                                        >Save</mercur-button>
                                    </div>
                                </template>
                            </field-group>
                        </template>
                        <template v-else>
                            <p>Please save the rule to get the distinct values to map</p>
                        </template>
                    </template>

                </div>
            </div>
        </div>
        <div class="align-right mt-5">
            <mercur-button
                class="btn btn-yellow"
                :disabled="isSavingProduct"
                @click="saveAndValidate"
                data-test="supplierImportProductProceed"
            >Save and continue</mercur-button>
        </div>
        <mercur-dialog class="dialog" :is-open.sync="incompatibleMappingDialogActive">
            <div slot="header">
                Incompatible mapping found
            </div>
            <div>
                <p class="max-width--700">We have found one or more incompatible mapping. Please take note of the incompatible mappings and click the button to clear them. Afterwards set them to their correct value. They will be marked with an exclamation mark.</p>
                <div v-for="(values, message) in incompatibleMappings" :key="message">
                    <div v-if="values.length">
                        <h3>{{message}}</h3>
                        <table class="incompatible-mapping">
                            <tr>
                                <th v-for="headerName in Object.keys(values[0]).filter(header => header !== 'mappingSet')" :key="headerName">{{headerName}}</th>
                            </tr>
                            <tr v-for="(incompatibleValue, key) in values" :key="key">
                                <template v-for="(value, property) in incompatibleValue">
                                    <td v-if="property !== 'mappingSet'" :key="property" class="incompatible-mapping__cell">
                                        <pre v-if="property === 'mappedAttributeOption'">{{value}}</pre>
                                        <template v-else-if="property === 'columnNames'">
                                            {{value.join(', ')}}
                                        </template>
                                        <template v-else-if="property !== 'mappingSet'">
                                            {{value}}
                                        </template>
                                    </td>
                                </template>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <mercur-button class="btn" @click="incompatibleMappingDialogActive = false">Close</mercur-button>
                <mercur-button class="btn btn-yellow" @click="clearIncompatibleMappings">Clear incompatible mapping</mercur-button>
            </div>
        </mercur-dialog>
    </mercur-card>
</template>
<script>
import FieldGroup from '../../../components/utils/FieldGroup'
import CONFIG from '@root/config'
import SupplierImportedProductMixin from './SupplierImportedProductMixin'
import { arraysEqualValues } from '../../../components/utils/Utils'
import PrettySelect from '../../../components/utils/PrettySelect'
import AddAttributeOptionComponent from '../../../components/products/AddAttributeOptionComponent'
import Alert from '../../../components/Alert'
export default {
    name: 'SupplierImportedProductCsvFileValueMapping',
    components: { PrettySelect, FieldGroup, AddAttributeOptionComponent, Alert },
    mixins: [SupplierImportedProductMixin],
    props: {
        attributes: {},
    },
    data () {
        return {
            availableAttributes: [],
            attributeValidations: null,
            columnMapping: null,
            selectedConnection: null,
            selectedColumnSet: null,
            valueMapping: {},
            sampleData: null,
            isGettingImportedValues: false,
            isAddingCustomOption: false,
            incompatibleMappingDialogActive: false,
            incompatibleMappings: {
                attributeNotFound: [],
                attributeOptionNotFound: [],
            },
        }
    },
    computed: {
        uploadRoute () {
            return {
                name: 'SupplierImportedProductUpload',
                params: {
                    ...this.$route.params,
                },
            }
        },
        payload () {
            const clone = JSON.parse(JSON.stringify(this.valueMapping))
            Object.entries(clone).forEach(([columnSetIndex, columnSet]) => {
                Object.entries(columnSet.values).forEach(([attributeName, value]) => {
                    for (let key in value) {
                        if (value.hasOwnProperty(key) && value[key]) {
                            delete value[key]['details']
                            delete value[key]['isRecommended']
                        }
                    }
                })
            })

            return {
                columnMapping: this.columnMapping,
                valueMapping: clone,
            }
        },
        distinctValues () {
            if (!this.selectedConnection || !this.selectedColumnSet || this.isGettingImportedValues) {
                return null
            }
            return this.getDistinctValuesForColumnAndConnection(this.selectedColumnSet.columnNames, this.selectedConnection.connectionName)
        },
        sortedOptions () {
            if (!this.selectedConnection || !this.supplierProductImportedDetails || !this.supplierProductImportedDetails.attributeConfiguration) {
                return null
            }

            if (!this.supplierProductImportedDetails.attributeConfiguration[this.selectedConnection.connectionName]) {
                return this.attributes[this.selectedConnection.connectionName]
            }

            const topOptions = JSON.parse(JSON.stringify(this.supplierProductImportedDetails.attributeConfiguration[this.selectedConnection.connectionName]))
            const mappedTopOptions = topOptions.map(e => e.option)
            const topArray = []
            const bottomArray = []
            this.attributes[this.selectedConnection.connectionName].forEach((item) => {
                if (mappedTopOptions.includes(item.option)) {
                    topArray.push(item)
                } else {
                    bottomArray.push(item)
                }
            })
            const sortedArray = [...topArray.sort(), ...bottomArray.sort()]
            return [...sortedArray.reduce((map, obj) => map.set(obj.attributeId, obj), new Map()).values()]
        },
        firstItemOfSampleData () {
            if (!this.supplierProductImportedDataSample || (Array.isArray(this.supplierProductImportedDataSample) && !this.supplierProductImportedDataSample.length)) {
                return null
            }
            const firstColumnName = this.selectedColumnSet.columnNames[0].replace(' ', '_')
            return this.supplierProductImportedDataSample[firstColumnName][0]
        },
        explodedValue () {
            if (!this.firstItemOfSampleData || !this.selectedConnection || !this.selectedConnection.value) {
                return
            }

            return this.firstItemOfSampleData.split(this.selectedConnection.value).filter(e => e !== '')
        },
    },
    methods: {
        checkIncompatibleMappings () {
            this.$set(this, 'incompatibleMappings', {
                attributeNotFound: [],
                attributeOptionNotFound: [],
            })
            this.valueMapping.forEach(mappingSet => {
                const columnNames = mappingSet.columnNames
                Object.entries(mappingSet.values || []).forEach(([attributeName, distinctValues]) => {
                    Object.entries(distinctValues || []).forEach(([distinctValue, mappedAttributeOption]) => {
                        if (!mappedAttributeOption || !this.attributes) {
                            return
                        }
                        if (!this.attributes[attributeName]) {
                            this.incompatibleMappings.attributeNotFound.push({
                                mappingSet,
                                columnNames,
                                attributeName,
                                distinctValue,
                                mappedAttributeOption,
                            })
                            console.warn('attribute not found', {
                                mappingSet,
                                columnNames,
                                attributeName,
                                distinctValue,
                                mappedAttributeOption,
                            })
                            return
                        }
                        if (!this.attributes[attributeName].find(option => option.attributeId === mappedAttributeOption.attributeId)) {
                            this.incompatibleMappings.attributeOptionNotFound.push({
                                mappingSet,
                                columnNames,
                                attributeName,
                                distinctValue,
                                mappedAttributeOption,
                            })
                            console.warn('attribute option not found', {
                                mappingSet,
                                columnNames,
                                attributeName,
                                distinctValue,
                                mappedAttributeOption,
                            })
                        }
                    })
                })
            })
            if (this.incompatibleMappings.attributeNotFound.length || this.incompatibleMappings.attributeOptionNotFound.length) {
                this.incompatibleMappingDialogActive = true
            }
        },
        clearIncompatibleMappings () {
            Object.values(this.incompatibleMappings).forEach(values => {
                values.forEach(incompatibleMapping => {
                    this.$set(incompatibleMapping.mappingSet.values[incompatibleMapping.attributeName], incompatibleMapping.distinctValue, null)
                })
            })
            this.incompatibleMappingDialogActive = false
        },
        isConnectionSelected (connection, columnSet) {
            if (!this.selectedConnection) {
                return false
            }
            return connection.connectionName === this.selectedConnection.connectionName
        },
        getSampleDataForColumn (column) {
            if (!this.sampleData) {
                return []
            }
            return this.sampleData.map(sample => sample[column.columnName])
        },
        setSelectedConnection (connection, column) {
            this.$set(this, 'selectedConnection', JSON.parse(JSON.stringify(connection)))
            this.$set(this, 'selectedColumnSet', column)
            // this.getImportedValues(this.selectedConnection)
        },
        storeSelectedConnection () {
            this.$set(this.selectedColumnSet.mappedTo, this.selectedConnection.connectionName, JSON.parse(JSON.stringify(this.selectedConnection)))
            const changedItemIndex = this.columnMapping.findIndex(e => {
                return e.type === this.selectedColumnSet.type && JSON.stringify(e.columnNames) === JSON.stringify(this.selectedColumnSet.columnNames)
            })
            this.$set(this.columnMapping, changedItemIndex, this.selectedColumnSet)
            this.saveProduct({
                validateColumnMappings: true,
            }).then(() => {
                this.getImportedValues(this.selectedConnection)
            })
        },
        getImportedValues (connection = null) {
            const url = CONFIG.API.ROUTES.SUPPLIERS.IMPORTED_PRODUCTS.LIST_IMPORTED_VALUES
                .replace('{supplierId}', this.supplierId)
                .replace('{supplierProductImportedId}', this.supplierProductImportedId)

            let fieldNames = this.supplierProductImportedDetails.columnMapping

            if (connection) {
                fieldNames = [{
                    ...this.selectedColumnSet,
                    mappedTo: {
                        [connection.connectionName]: connection,
                    },
                }]
            }

            const payload = {
                fieldNames,
            }

            this.addJob(url)
            this.isGettingImportedValues = true
            return this.$api.post(url, payload).then(({ data }) => {
                const valueMapping = data.data

                Object.values(valueMapping).forEach(valueMappingSet => {
                    let columnMappingSet = null
                    if (this.valueMapping) {
                        columnMappingSet = this.valueMapping.find(mappingSet => arraysEqualValues(mappingSet.columnNames.sort(), valueMappingSet.columnNames.sort()))
                    }
                    if (!columnMappingSet) {
                        columnMappingSet = {
                            ...JSON.parse(JSON.stringify(valueMappingSet)),
                            columnNames: valueMappingSet.columnNames.sort(),
                            values: {},
                        }
                        delete columnMappingSet.mappedTo
                        this.valueMapping.push(columnMappingSet)
                    }

                    Object.entries(valueMappingSet.mappedTo).forEach(([connectionName, connectionValueMap]) => {
                        if (!connectionValueMap.items || !Object.keys(connectionValueMap.items).length) {
                            columnMappingSet.values[connectionName] = {}
                            return
                        }

                        this.$set(columnMappingSet.values, connectionName, Object.fromEntries(Object.entries(connectionValueMap.items).map(([value, details]) => {
                            const currentValue = this.getConnectionMappingValue(valueMappingSet.columnNames, connectionName, value)
                            let composedValue = {
                                attributeId: null,
                            }
                            if (currentValue && currentValue.attributeId) {
                                composedValue = currentValue
                            }

                            if (this.sortedOptions && this.sortedOptions.length === 1) {
                                composedValue = this.sortedOptions[0]
                                return [value, composedValue]
                            }

                            // PART TO SELECT BLUE OPTION IF THERE IS ONLY ONE
                            if (this.supplierProductImportedDetails.attributeConfiguration[this.selectedConnection.connectionName]) {
                                const topOptions = JSON.parse(JSON.stringify(this.supplierProductImportedDetails.attributeConfiguration[this.selectedConnection.connectionName]))
                                if (topOptions.length === 1) {
                                    composedValue = this.sortedOptions.find(e => e.option === topOptions[0].option)
                                    return [value, composedValue]
                                }
                            }

                            // PART TO SELECT RECOMMENDED OPTION IF IT IS THERE
                            if (!composedValue.option && details.recommendation && details.recommendation.length) {
                                const firstItem = details.recommendation[0]
                                if (this.sortedOptions.map((e) => e.attributeId).includes(firstItem.attributeId)) {
                                    composedValue = {
                                        attributeId: firstItem.attributeId,
                                        attributeName: firstItem.attributeName,
                                        option: firstItem.attributeOption,
                                        postfix: firstItem.postfix,
                                        isRecommended: true,
                                    }
                                }
                            }

                            composedValue.details = details

                            return [value, composedValue]
                        })))
                    })
                })
            }).catch(data => {
                console.error(data)
                this.$root.$emit('notification:global', {
                    message: `Getting distinct values failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(url)
                this.isGettingImportedValues = false
            })
        },
        getDistinctValuesForColumnAndConnection (columnNames, connectionName) {
            if (!this.valueMapping) {
                return []
            }

            // THIS IS NEEDED FOR WHEN WE HAVE TWO ATTRIBUTES i.e. @Print technique, @Print position,
            // because data from mercur comes sorted, and if this is not sorted arrays are not the same
            const sortedColumnNames = columnNames.sort()
            const columnMappingSet = this.valueMapping.find(mappingSet => arraysEqualValues(mappingSet.columnNames, sortedColumnNames))
            if (!columnMappingSet) {
                return null
            }
            return columnMappingSet.values[connectionName]
        },
        handleConnectionValueSelection (columnNames, connectionName, value, $event) {
            let attributeOption = null
            if ($event !== null) {
                attributeOption = this.attributes[connectionName].find(option => option.attributeId === $event)
                if (!attributeOption) {
                    console.error('no option found for ', arguments)
                    this.$root.$emit('notification:global', {
                        message: `Getting distinct values failed. Please try again.`,
                        type: 'error',
                    })
                }
            }
            const columnMappingSetIndex = this.valueMapping.findIndex(mappingSet => arraysEqualValues(mappingSet.columnNames, columnNames))
            this.$set(this.valueMapping[columnMappingSetIndex].values[connectionName], value, attributeOption)
        },
        getConnectionMappingValue (columnNames, connectionName, value) {
            const columnMappingSet = this.valueMapping.find(mappingSet => arraysEqualValues(mappingSet.columnNames, columnNames))

            if (columnMappingSet && columnMappingSet.values && columnMappingSet.values[connectionName] && columnMappingSet.values[connectionName][value] && this.sortedOptions) {
                const sortedOptionsAttributeIds = this.sortedOptions.map(e => e.attributeId)
                if (!sortedOptionsAttributeIds.includes(columnMappingSet.values[connectionName][value].attributeId)) {
                    const mappedValue = columnMappingSet.values[connectionName][value].option || ''

                    if (mappedValue !== '') {
                        this.$root.$emit('notification:global', {
                            message: `Mapped value '${mappedValue}' for '${connectionName}' is not on the list of possible options. Please select the correct value`,
                            type: 'error',
                        })
                    }

                    this.$set(columnMappingSet.values[connectionName], value, null)
                    console.warn('We have these values.')
                    console.warn('sortedOptionsAttributeIds', sortedOptionsAttributeIds)
                    console.warn('columnMappingSet', columnMappingSet)
                    console.warn('connectionName', connectionName)
                    console.warn('value', value)
                    console.warn('columnMappingSet.values[connectionName]', columnMappingSet.values[connectionName])
                    console.warn('columnMappingSet.values[connectionName][value]', columnMappingSet.values[connectionName][value])
                }

                return columnMappingSet.values[connectionName][value]
            }

            return {}
        },
        getConnectionProgress (connection, column) {
            if (column.type === 'DATA') {
                if (connection.type === null) {
                    return 'no'
                }

                if (connection.index && connection.value) {
                    return 'complete'
                }
                if (connection.type === 'VALUE') {
                    return 'complete'
                }
            }
            if (column.type === 'ATTRIBUTE') {
                if (connection.type === null) {
                    return 'no'
                }
                if (connection.type !== 'VALUE' && (connection.index === '' || connection.value === '')) {
                    return 'partial'
                }

                const columnMappingSet = this.valueMapping.find(mappingSet => arraysEqualValues(mappingSet.columnNames, column.columnNames))
                const valuesMappingIncomplete = !columnMappingSet || !columnMappingSet.values[connection.connectionName] || Object.values(columnMappingSet.values[connection.connectionName]).some(mappedValue => {
                    return !mappedValue || !mappedValue.attributeId
                })

                if (valuesMappingIncomplete) {
                    return 'partial'
                }
                return 'complete'
            }

            return 'partial'
        },

        setupData (attributesPromise) {
            // Clean up the value mapping to not have duplicate columnNames values for the same type
            this.$set(this, 'valueMapping', (this.supplierProductImportedDetails.valueMapping || [])
                .filter((column, columnIndex, valueMapping) => {
                    if (column.type !== 'ATTRIBUTE') {
                        return true
                    }
                    for (let i = columnIndex - 1; i >= 0; i--) {
                        if (valueMapping[i].type === column.type && JSON.stringify(valueMapping[i].columnNames) === JSON.stringify(column.columnNames)) {
                            return false
                        }
                    }
                    return true
                }).map(mapping => {
                    this.$set(mapping, 'values', Object.fromEntries(Object.entries(mapping.values || {})))
                    return mapping
                })
            )

            this.initialValues = {
                valueMapping: JSON.parse(JSON.stringify(this.valueMapping)),
            }
            attributesPromise.then(() => {
                this.checkIncompatibleMappings()
            })
        },
        save (payload = {}) {
            this.$emit('save', {
                ...this.payload,
                ...(payload || {}),
            })
        },
        saveAndValidate () {
            this.save(
                {
                    validateValueMappings: true,
                    onSuccess: () => {
                        this.skipCheckForChanges = true
                        this.$emit('pushRoute', { name: 'SupplierImportedProductFacilities' })
                    },
                })
        },
        columnTitle (type, names) {
            return `${type} - ${names.join(', ')}`
        },
        isTopArray (item) {
            if (!this.supplierProductImportedDetails.attributeConfiguration[this.selectedConnection.connectionName]) {
                return false
            }

            const topOptions = JSON.parse(JSON.stringify(this.supplierProductImportedDetails.attributeConfiguration[this.selectedConnection.connectionName])).map(e => e.option)
            if (topOptions.includes(item.option)) {
                return true
            }
            return false
        },
        addCustomOption (value, key) {
            this.isAddingCustomOption = true

            const payload = {
                'attributeName': this.selectedConnection.connectionName,
                'attributeOption': value,
            }

            const url = CONFIG.API.ROUTES.PRODUCTS.GENERATION.ATTRIBUTES.ADD_OPTION
            this.$parent.isSavingProduct = true
            this.isAddingCustomOption = true
            this.$api.post(url, payload).then(({ data }) => {
                const columnMappingSet = this.valueMapping.find(mappingSet => arraysEqualValues(mappingSet.columnNames, this.selectedColumnSet.columnNames))
                this.$emit('appendOption', data.data)
                this.$nextTick(() => {
                    columnMappingSet.values[this.selectedConnection.connectionName][key] = this.sortedOptions.find(e => e.attributeId === data.data.attributeId)
                })
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: err.message,
                    type: 'error',
                })
            }).finally(() => {
                this.$parent.isSavingProduct = false
                this.isAddingCustomOption = false
            })
        },
        checkForChanges () {
            if (!this.skipCheckForChanges && JSON.stringify(this.initialValues.valueMapping) !== JSON.stringify(this.valueMapping)) {
                return true
            }
            return false
        },
    },
    watch: {
        supplierProductImportedDetails: {
            handler (value) {
                if (!value || !this.supplierProductImportedDetails.columnMapping) {
                    return
                }
                this.columnMapping = JSON.parse(JSON.stringify(this.supplierProductImportedDetails.columnMapping))
            },
            immediate: true,
        },
        'selectedConnection.type' (value) {
            if (value === 'DELIMITER' && !this.supplierProductImportedDataSample) {
                this.$emit('getSample')
            }
        },
    },
    created () {
        this.$emit('getSample')
        const mercurAttributesUrl = CONFIG.API.ROUTES.PRODUCTS.GENERATION.ATTRIBUTES.LIST
        this.addJob(mercurAttributesUrl)
        const attributesPromise = this.$api.post(mercurAttributesUrl, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            this.availableAttributes = data.data.items.flatMap(attribute => attribute.attributeName)
        }).finally(() => {
            this.finishJob(mercurAttributesUrl)
        })
        if (this.supplierProductImportedDetails) {
            this.setupData(attributesPromise)
        } else {
            let unwatcher = this.$watch('supplierProductImportedDetails', () => {
                this.setupData(attributesPromise)
                unwatcher()
            })
        }
        this.$api.get(CONFIG.API.ROUTES.PRODUCTS.GENERATION.ATTRIBUTES.VALIDATIONS.LIST).then(({ data }) => {
            this.$set(this, 'attributeValidations', Object.fromEntries(data.data.map(validationRule => {
                return [validationRule.attributeName, validationRule]
            })))
        })
    },
}
</script>

<style lang="scss" scoped>

    .radio-list {
        list-style: none;
        padding: 0;
    }

    .connection {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &--is-selected {
            font-weight: 600;

            &:after {
                content: '';
                display: block;
                position: absolute;
                right: -40px;
                height: 100%;
                top: 4px;
                width: 30px;
                background-color: #f9f9f9;
                border-top: 1px solid darken(#f9f9f9, 7%);
                border-bottom: 1px solid darken(#f9f9f9, 7%);
            }
        }
    }

    .no-wrap {
        flex-wrap: nowrap;
    }

    .column {
        margin-left: 10px;
    }
    .material-design-icon {
        font-size: 24px;
    }
    .save-connection {
        position: sticky;
        bottom: 20px;
        z-index: 30;
    }
    .concat-info-icon {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 100px;
        background-color: #aaa;
        color: white;
        line-height: 15px;
        font-size: 10px;
        font-style: italic;
        text-align: center;
    }
    .recommended-message {
        font-size: 11px;
        color: #00bf25;
        margin-top: -15px;
        display: block;
        margin-bottom: 15px;
        text-align: right;
    }
    .explanation {
        position: relative;
        display: block;
        color: blue;
        margin-top: -15px;
        margin-bottom: 25px;
        font-size: 0.7em;
        line-height: 1.2em;
    }
    .inline-add {
        display: inline-block;
        vertical-align: middle;
    }
    .inline-select {
        display: inline-block;
        width: calc(100% - 109px);
    }
    .incompatible-mapping {
        width: 100%;

        &__cell {
            background-color: #e8e8e8;
            padding: 2px;
            vertical-align: top;
        }
    }
</style>
